module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"387510143227949"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sensatia Botanicals","short_name":"Sensatia","start_url":"/","background_color":"#ffff","theme_color":"#9DBFAE","display":"standalone","icon":"src/images/favicon/favicon-192x192.png","icons":[{"src":"src/images/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/images/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/images/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon/favicon-48x48.ico","sizes":"48x48","type":"image/ico"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5dcb005d827a1f835fdd5b42faf2ba11"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/app/sign-up/","/app/jp/sign-up/","/app/id/sign-up/","/app/login/","/app/jp/login/","/app/id/login/"],"crumbLabelUpdates":[{"pathname":"/eu","crumbLabel":"Home"},{"pathname":"/id","crumbLabel":"Home"},{"pathname":"/jp","crumbLabel":"Home"},{"pathname":"/en","crumbLabel":"Home"}],"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-identity-gotrue/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://sensatia.netlify.app"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-W93K2X5","G-Q8LPMEQG95"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/app/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
