import 'isomorphic-fetch';
import React from 'react';
import { CartProvider } from 'react-use-cart';
import { CookiesProvider } from 'react-cookie';

import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'
import 'react-medium-image-zoom/dist/styles.css'

import './src/components/breadcrumb.css'
import './src/components/RcNotification/index.css'

import { CheckoutProvider } from './src/context/Checkout';

export const wrapRootElement = ({ element }) => {
  return (
    <CookiesProvider>
      <CartProvider>
        <CheckoutProvider>
          {element}      
        </CheckoutProvider>
      </CartProvider>
    </CookiesProvider>
  );
};

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    const currentPath = window.location.pathname;
    const userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage.startsWith('id') && !currentPath.startsWith('/id/')) {
      window.location.replace('/id' + currentPath);
    } else if (userLanguage.startsWith('ja') && !currentPath.startsWith('/jp/')) {
      window.location.replace('/jp' + currentPath);
    }
  }
};